var modules = ['ui.mask', 'ngFileUpload', 'ui-notification', 'ngProgress',
    'angularUtils.directives.dirPagination', 'ui.bootstrap', 'pascalprecht.translate', 'ngSanitize']
export var app = angular.module('app', modules);

app.factory('TranslationErrorHandler', ['$q', '$log', function ($q, $log) {
    return function (part, lang, response) {
        $log.info('The translation "' + part + '/' + lang + '" part was not loaded.');

        return $q.when({});
    };
}]).run(['$rootScope', '$translate', 'cfg', function ($rootScope, $translate, cfg) {
    $rootScope.cfg = cfg;
    $rootScope.$on('$translatePartialLoaderStructureChanged', function (data) {
        $translate.refresh();
    });
}]);

app.config(["$rootScopeProvider", function ($rootScopeProvider) {
    // Raise this from 10 because a big El Salvador service can trigger a form data update more times on initial load
    $rootScopeProvider.digestTtl(30);
}]);
